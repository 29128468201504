// BookingController "pay" sync.
const MERCADO_PAGO = 'ML';
const PAYPAL = 'PL';
const TRANSBANK = 'TBANKCL';
const CREDOMATIC = 'BAC';
const PROMERICA = 'PROMERICA';
const PRISMA = 'PRISMA';
const STRIPE = 'STRIPE';
const POYNT = 'POYNT';
const GENIUS = 'GENIUS';
const REDSYS = 'REDSYS';

export const hasCredentials = payMethodObject => {
  if (!payMethodObject) return false;

  switch (payMethodObject.name) {
    case 'MPComponent':
      if (!payMethodObject.publicToken) return false;
      break;
    case 'PrismaComponent':
      if (!payMethodObject.publicToken) return false;
      break;
    case 'StripeComponent':
      if (!payMethodObject.publicApiKey) return false;
      break;
    case 'PaypalComponent':
      if (!payMethodObject.clientId) return false;
      break;
    case 'PoyntComponent':
      if (!payMethodObject.businessId && !payMethodObject.applicationId) return false;
      break;
    case 'GeniusComponent':
      if (!payMethodObject.webApiKey) return false;
      break;
    case 'RedsysComponent':
      if (!payMethodObject.connectionUrl) return false;
      break;
    default:
      return false;
  }

  return true;
};

export const getPayMethodCode = payMethodObject => {
  if (!payMethodObject) return false;
  let code = '';

  switch (payMethodObject.name) {
    case 'MPComponent':
      code = MERCADO_PAGO;
      break;
    case 'PrismaComponent':
      code = PRISMA;
      break;
    case 'StripeComponent':
      code = STRIPE;
      break;
    case 'PaypalComponent':
      code = PAYPAL;
      break;
    case 'PoyntComponent':
      code = POYNT;
      break;
    case 'GeniusComponent':
      code = GENIUS;
      break;
    case 'RedsysComponent':
      code = REDSYS;
      break;
  }

  return code;
};

export const getConfigurationObject = (payMethodObject, response) => {
  let object = {};

  switch (payMethodObject.name) {
    case 'MPComponent':
      object = { publicToken: response.data.publicKey };
      break;
    case 'PrismaComponent':
      object = {
        isSandbox: response.data.isSandBox,
        publicApiKey: response.data.publicApiKey,
      };
      break;
    case 'PaypalComponent':
      object = {
        isSandBox: response.data.isSandBox,
        clientId: response.data.isSandBox
          ? response.data.sandBoxCliendID
          : response.data.productionClientID,
      };
      break;
    case 'PoyntComponent':
      object = {
        businessId: response.data.businessId,
        applicationId: response.data.collectApplicationId,
      };
      break;
    case 'GeniusComponent':
      object = { webApiKey: response.data.webApiKey };
      break;
    case 'StripeComponent':
      object = { publicApiKey: response.data.publicApiKey };
      break;
    case 'RedsysComponent':
      object = { connectionUrl: response.data.connectionUrl };
      break;
  }

  return object;
};
