import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { props, defaultProps } from './RangeDatePicker.props';
import { START_DATE } from 'react-dates/constants';
import { DateRangePicker } from 'react-dates';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class RangeDatePicker extends Component {
  static propTypes = props;
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate || null,
      endDate: props.endDate || null,
      focusedInput: props.focusedInput || START_DATE,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return { startDate: nextProps.startDate, endDate: nextProps.endDate };
  }

  handleDatesChange = (startDate, endDate) => {
    const { onDatesChange } = this.props;
    onDatesChange(startDate, endDate);
    this.setState({ startDate, endDate });
  };

  handleFocusChange = focusedInput => {
    const { onFocusChange } = this.props;
    onFocusChange(focusedInput);
    this.setState({ focusedInput });
  };

  handleWeekDays = day => {
    const { i18n } = this.props;
    const lang = i18n.language.toUpperCase();

    if (lang === 'ES' || lang === 'ES-AR' || lang === 'ES-CL' || lang === 'ES-MX') {
      day._locale._weekdaysMin = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];
    } else if (lang === 'PT') {
      day._locale._weekdaysMin = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];
    } else if (lang === 'PL') {
      day._locale._weekdaysMin = ['NIED', 'PONIE', 'WTOR', 'ŚRO', 'CZWAR', 'PIĄ', 'SOB'];
    } else if (lang === 'LT') {
      day._locale._weekdaysMin = ['PIR', 'ANT', 'TRE', 'KET', 'PEN', 'ŠEš', 'SEK'];
    } else if (lang === 'AL') {
      day._locale._weekdaysMin = ['DIM', 'HËN', 'MAR', 'MËR', 'ENJ', 'PRE', 'SHT'];
    } else if (lang === 'IT') {
      day._locale._weekdaysMin = ['DOM', 'LUN', 'MAR', 'MER', 'GIO', 'VEN', 'SAB'];
    } else if (lang === 'NL') {
      day._locale._weekdaysMin = ['ZON', 'MA', 'DI', 'WO', 'DO', 'VR', 'ZA'];
    } else if (lang === 'RU') {
      day._locale._weekdaysMin = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
    } else {
      day._locale._weekdaysMin = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    }
    return day.format('D');
  };

  handleMonthsName = month => {
    const { i18n } = this.props;
    const lang = i18n.language.toUpperCase();

    if (lang === 'ES' || lang === 'ES-AR' || lang === 'ES-CL' || lang === 'ES-MX') {
      month._locale._months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ];
    } else if (lang === 'PT') {
      month._locale._months = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];
    } else if (lang === 'PL') {
      month._locale._months = [
        'Styczeń',
        'Luty',
        'Marsz',
        'Kwiecień',
        'Móc',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień',
      ];
    } else if (lang === 'LT') {
      month._locale._months = [
        'Sausis',
        'Vasaris',
        'Kovas',
        'Balandis',
        'Gegužė',
        'Birželis',
        'Liepa',
        'Rugpjūtis',
        'Rugsėjis',
        'Spalis',
        'Lapkritis',
        'Gruodis',
      ];
    } else if (lang === 'AL') {
      month._locale._months = [
        'Janar',
        'Shkurt',
        'Mars',
        'Prill',
        'Maj',
        'Qershor',
        'Korrik',
        'Gusht',
        'Shtator',
        'Tetor',
        'Nëntor',
        'Dhjetor',
      ];
    } else if (lang === 'IT') {
      month._locale._months = [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ];
    } else if (lang === 'NL') {
      month._locale._months = [
        'Januari',
        'Februari',
        'Maart',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Augustus',
        'September',
        'Oktober',
        'November',
        'December',
      ];
    } else {
      month._locale._months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
    }
    return month.format('MMMM');
  };

  render() {
    const {
      startDateId,
      endDateId,
      showDefaultInputIcon,
      inputIconPosition,
      block,
      noBorder,
      settings,
      isDayBlocked,
      isOutsideRange,
      t,
    } = this.props;

    return (
      <DateRangePicker
        startDateId={startDateId}
        startDate={this.state.startDate}
        endDateId={endDateId}
        endDate={this.state.endDate}
        displayFormat={t('dateFormat')}
        renderDayContents={this.handleWeekDays}
        renderMonthText={this.handleMonthsName}
        onDatesChange={({ startDate, endDate }) => this.handleDatesChange(startDate, endDate)}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.handleFocusChange(focusedInput)}
        showDefaultInputIcon={showDefaultInputIcon}
        inputIconPosition={inputIconPosition}
        block={block}
        noBorder={noBorder}
        isDayBlocked={day => {
          isDayBlocked(day, this.state.focusedInput);
        }}
        readOnly
        isOutsideRange={isOutsideRange}
        {...settings}
      />
    );
  }
}

const mapStateToProps = ({ global }) => ({
  langs: global.listLanguages,
});

const RangeDatePickerComp = withTranslation()(RangeDatePicker);

export default connect(mapStateToProps)(RangeDatePickerComp);
