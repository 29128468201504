import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoadingWrapperStyled } from './RedsysSection.styled';
import { withTranslation } from 'react-i18next';
import { CustomLoading } from 'components/';
import { tlink } from 'utils/translationHelper';
import queryString from 'query-string';
import { history } from '/store';
import {
  payBooking,
  payInfraction,
  getBooking,
  getRedsysValidateRedirectSignature,
} from 'services/api';
import Storage from 'utils/storage';
import { is_gtag_enabled, gtag_purchase } from '../../../utils/googleTagsHelper';

class RedsysSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        bookingId: '0',
      },
    };
  }

  async componentDidMount() {
    const {
      location,
      t,
      i18n,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
    } = this.props;

    const parseQs = queryString.parse(location.search);
    const storage = Storage.getElement('redsysPaymentParams', false);

    if (parseQs && parseQs.Ds_MerchantParameters && storage) {
      const ds_MerchantParameters = parseQs.Ds_MerchantParameters;
      const ds_Signature = parseQs.Ds_Signature;
      const ds_SignatureVersion = parseQs.Ds_SignatureVersion;
      const paymentParams = JSON.parse(storage);

      try {
        var response = await getRedsysValidateRedirectSignature(
          paymentParams.branchOfficeId,
          paymentParams.order,
          ds_MerchantParameters,
          ds_Signature,
        );

        if (response.success) {
          Storage.setElement('redsysPaymentParams', '', false); //delete storage

          var params = {
            gatewayId: 'REDSYS',
            bookingId: paymentParams.bookingId,
            infractionId: paymentParams.infractionId,
            price: paymentParams.price,
            currencyCode: paymentParams.currencyCode,
            exchangeRate: paymentParams.exchangeRate,
            branchOfficeId: paymentParams.branchOfficeId,
            customerId: paymentParams.customerId,
            lastName: paymentParams.customerLastName,
            orderID: paymentParams.order,
            extraData: ds_MerchantParameters,
          };
          if (paymentParams.bookingId && paymentParams.bookingId > 0) {
            await payBooking(params);

            if (is_gtag_enabled(googleTagManager)) {
              const booking = getBooking(null, paymentParams.bookingId);
              if (booking && booking.data) gtag_purchase(booking.data, settings);
            }
          } else {
            await payInfraction(params);
          }

          history.push(
            `${tlink(
              '__Routes.paymentSuccess',
              t,
              i18n,
              null,
              settings.configurations.langConfig,
            )}`,
          );
        } else {
          history.push(
            `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
            { error: response.errorCode },
          );
        }
      } catch (error) {
        history.push(
          `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
          {
            error: t('payBookingGatewayFailGenericError', {
              bookingId: paymentParams.bookingId || paymentParams.infractionId,
            }),
          },
        );
      }
    }
  }

  render() {
    const { t, loadingGif, loadingMessage } = this.props;
    return (
      <LoadingWrapperStyled className="col-md-12">
        <CustomLoading
          loadingGif={loadingGif || t('loadingGif')}
          loadingMessage={loadingMessage}
        ></CustomLoading>
      </LoadingWrapperStyled>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(RedsysSuccess)));
