import React from 'react';
import { Title, Paragraph, Btn } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyledRedsysSection } from './RedsysSection.styled';
import { withTranslation } from 'react-i18next';
import { tt, tlink } from 'utils/translationHelper';
import queryString from 'query-string';
import { history } from '/store';
import { getRedsysInitializeRedirect } from 'services/api';
import { BookingStatus } from '../../../utils/constants';
import { Typography } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import {
  is_gtag_enabled,
  gtag_checkout_process,
  gtag_checkout_option,
  gtag_purchase,
} from '../../../utils/googleTagsHelper';
import BasePaymentSection from '../basePaymentComponent/BasePaymentSection';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';
import PartialPayWarning from '../partialPayWarning';
import Storage from 'utils/storage';

class RedsysSection extends BasePaymentSection {
  constructor(props) {
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        qsamount: 0,
        bookingId: '0',
        infractionId: '0',
        customerId: '0',
        act: '',
        branchOfficeId: 0,
        promotionId: '',
        currencyCode: '',
        exchangeRate: 1,
      },
      transactionLoading: true,
      dolarPrice: 0,
      exchangeRate: 1,
      dsMerchantParameters: '',
      dsSignatureVersion: '',
      dsSignature: '',
    };
  }

  componentWillMount() {
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      try {
        let partial = parseFloat(parseQs.amount);
        if (partial != 0) {
          this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
        }
      } catch {}
    }
  }

  async componentDidMount() {
    const {
      location,
      bookings,
      infractions,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      listTaxPayerTypes,
      i18n,
      defaultCurrency,
    } = this.props;
    const parseQs = queryString.parse(location.search);

    if (this.isPayingBooking() && bookings.confirmation != null) {
      if (is_gtag_enabled(googleTagManager)) {
        gtag_checkout_process(
          { number: 3, option: 'Online Payment' },
          bookings.confirmation,
          settings,
        );
        gtag_checkout_option({ number: 3, option: 'Poynt' });
      }

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getTotalToPay(),
          bookingId: bookings.confirmation.id,
          email: bookings.confirmation.customer.emailAddress,
          customerId: bookings.confirmation.customer.id,
          customerLastName: bookings.confirmation.customer.lastName,
          branchOfficeId: bookings.confirmation.deliveryPlace.branchOfficeId,
          promotionId: parseQs.promotionid || null,
          currencyCode: bookings.confirmation.currency,
          exchangeRate: 1,
        },
      }));
    }

    if (this.isPayingInfraction() && parseQs.customerid) {
      const infraction = infractions.current;
      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getInfractionTotalToPay(),
          infractionId: infraction.id,
          act: infraction.act,
          customerId: parseQs.customerid,
          customerLastName: 'EMPTY',
          branchOfficeId: infraction.booking?.deliveryPlace?.branchOfficeId || null,
          currencyCode: infraction.booking?.currency || defaultCurrency.isoCode,
          exchangeRate: 1,
        },
      }));
    }
  }

  generateOrderId() {
    let numbers = '0123456789';
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let order = '';

    for (let i = 0; i < 4; i++) {
      order += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    for (let i = 4; i < 12; i++) {
      order += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return order;
  }

  async getInitializeRedirect() {
    const { t, i18n, settings } = this.props;

    let paymentParams = this.state.paymentInfo;
    var paymentParamsCopy = {
      ...paymentParams,
      isPayingInfraction: this.isPayingInfraction() && parseQs.customerid,
      order: this.generateOrderId(),
    };

    if (paymentParamsCopy.price == 0) return;

    getRedsysInitializeRedirect(
      paymentParamsCopy.branchOfficeId,
      paymentParamsCopy.price,
      paymentParamsCopy.currencyCode,
      paymentParamsCopy.order,
    )
      .then(res => {
        Storage.setElement('redsysPaymentParams', JSON.stringify(paymentParamsCopy), false);
        this.setState(prevState => ({
          ...prevState,
          transactionLoading: false,
          dsMerchantParameters: res.data.response.dsMerchantParameters,
          dsSignatureVersion: res.data.response.dsSignatureVersion,
          dsSignature: res.data.response.dsSignature,
        }));
      })
      .catch(error => {
        history.push(
          `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
          { error: t('paymentGenericError') },
        );
      });
  }

  getInfractionTotalToPay() {
    const { infractions } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    const infraction = infractions.current;
    if (qsamount && Math.abs(infraction.amount) >= qsamount) {
      return Math.abs(qsamount);
    } else {
      return infraction.amount;
    }
  }

  showOrderIdentifier = () => {
    const { t, bookings, settings } = this.props;
    const confirmedOrderText = settings?.paymentConfiguration?.confirmedOrderText;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      const bookingStatus = bookings.confirmation.currentStatus;

      if ([BookingStatus.reserved, BookingStatus.confirmed].includes(bookingStatus)) {
        return (
          tt(confirmedOrderText, t, true, { booking_id }) || t('ordenConfirmada', { booking_id })
        );
      }

      return t('orden', { booking_id });
    }

    if (this.isPayingInfraction())
      return `ACTA ${this.state.paymentInfo.act?.toString().padStart(7, '0')}`;
  };

  isPayingBooking = () => {
    const { bookings, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.bookingid || (bookings && bookings.confirmation);
  };

  isPayingInfraction = () => {
    const { infractions, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.infractionid && parseQs.customerid && infractions && infractions.current;
  };

  getPayButtonText = () => {
    const { t } = this.props;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      return this.state.formLoading
        ? t('pagandoReserva', { booking_id })
        : t('pagarReserva', { booking_id });
    }

    if (this.isPayingInfraction()) {
      const act = this.state.paymentInfo.act?.toString().padStart(7, '0');
      return this.state.formLoading ? t('payingInfraction', { act }) : t('payInfraction', { act });
    }
  };

  render() {
    const {
      t,
      title,
      subtitle,
      credentials,
      currentCurrency,
      defaultCurrency,
      webCurrency,
    } = this.props;

    const { dsMerchantParameters, dsSignatureVersion, dsSignature } = this.state;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const showExchangeMsg = applyExchangeRate;

    let returnFinalPriceOnly = true;

    let totalToPay = this.showTotalToPay(returnFinalPriceOnly);
    if (applyExchangeRate)
      totalToPay = getPriceWithExchangeRate(
        totalToPay,
        currentCurrency,
        defaultCurrency,
        webCurrency,
      );

    if (!credentials) return null;

    if (!dsMerchantParameters && !dsSignatureVersion && !dsSignature) this.getInitializeRedirect();

    let formAction = credentials.connectionUrl + '/sis/realizarPago';

    return (
      <StyledRedsysSection className="col-md-9">
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="col-md-12 p-0 LogosContainer">
            <img
              width="60"
              height="60"
              className="mr-4"
              src="/images/ssl.jpg"
              alt={t('CertificadoSSL')}
            />
            <div className="SecureLogoContainer">
              <div className="LogoContainer">
                <VerifiedUserOutlinedIcon />
              </div>
              <div>
                <Typography variant="body1" className="CompraTitle">
                  {t('CompraSegura')}
                </Typography>
                <Typography variant="body1"> {t('SafeTransactionsSite')}</Typography>
              </div>
            </div>
          </div>
          <div className="adicionales-block pb-1 pt-3 payment-code">
            <div className="col-md-12 p-0">
              <p className="mb-1">
                <strong>{this.showOrderIdentifier()}</strong>
              </p>
              <p>
                <strong>
                  {t('totalToPay')} {this.showTotalToPay()}
                </strong>
              </p>
              <hr />
              <PartialPayWarning />
            </div>
            {showExchangeMsg && (
              <Paragraph className="rent-days mb-0 mt-2" color={'red'}>
                <strong>{`${t('chargedPaymentMsg')} ${
                  currentCurrency?.isoCode
                } ${totalToPay.toFixed(2)} `}</strong>
              </Paragraph>
            )}
          </div>
          <div className="row adicionales-block pb-3 pt-3">
            <form name="from" action={formAction} method="POST">
              <input type="hidden" name="Ds_SignatureVersion" value={dsSignatureVersion} />
              <input type="hidden" name="Ds_MerchantParameters" value={dsMerchantParameters} />
              <input type="hidden" name="Ds_Signature" value={dsSignature} />
              <div className="mt-3 ml-3">
                <Btn
                  type="submit"
                  name="btnSubmit"
                  text={
                    this.state.formLoading || this.state.transactionLoading
                      ? t('loadingTransbank')
                      : t('transbankBtn')
                  }
                  disabled={this.state.formLoading || this.state.transactionLoading}
                />
                <p className="mb-1"> ({t('redirectRedsys')}) </p>
              </div>
            </form>
          </div>
        </div>
      </StyledRedsysSection>
    );
  }
}

const mapStateToProps = ({ bookings, infractions, siteConfiguration, global }) => ({
  bookings: bookings,
  infractions: infractions,
  settings: siteConfiguration.settings,
  listCurrencies: global.listCurrencies,
  generalSettings: global.generalSettings,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
});
export default connect(mapStateToProps, {})(withRouter(withTranslation()(RedsysSection)));
